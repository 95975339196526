<template>
  <div style="height: 100vh">
    <div class="columns is-centered is-vcentered">
      <div class="column is-half" style="height: 100%">
        <div class="card">
          <div class="card-header has-background-dark">
            <div class="card-header-title has-text-white is-centered">
              <!--<span>{{ siteName }} Papara Transfer</span>-->
              <img width="300" :src="`/logos/${site}/bank.svg`" />
            </div>
          </div>
          <div class="card-content is-centered" v-if="tab === 0">
            <p class="mb-4 p-3 has-text-centered has-background-danger has-text-white">{{ errMsg }}</p>
            <b-button class="mr-3" type="is-danger" size="is-small" @click="windowClose">Kapat</b-button>
          </div>
          <section class="section is-main-section" v-if="tab === 1">
            <card-component title="Yatırım Bilgileriniz">
              <form @submit.prevent="save">
                <b-field label="Ad & Soyad" message="Papara hesabınızdaki ad soyad ile aynı olmalıdır">
                  <b-input v-model="form.name" size="is-small" />
                </b-field>
                <b-field label="Yatırım tutarı" message="Minimum 50 TRY">
                  <b-input v-model="form.amount" size="is-small" />
                </b-field>
                <b-field label="Banka seçiniz" message="Lütfen yatırım yapacağınız bankayı seçip işlemi tamamlayınız.">
                  <b-select v-model="form.bankId" type="is-small" expanded>
                    <option v-for="account in accountList" :key="account.id" :value="account.id">{{ account.bankName }} -
                      {{ account.bankAccountName }} - {{ account.iban }}</option>
                  </b-select>
                </b-field>
                <div class="is-flex is-full is-justify-content-end">
                  <b-button type="is-danger" size="is-small" class="mr-3" @click="cancel">İptal Et</b-button>
                  <b-button :disabled="!isValid" type="is-success" size="is-small" native-type="submit">Devam
                    et</b-button>
                </div>
              </form>
            </card-component>
          </section>
          <section class="section is-main-saction" v-if="tab === 2">
            <div class="card">
              <div class="card-content is-centered">
                <p class="mb-4 has-text-centered">Lütfen aşağıda belirtilen adrese tutarında havale & eft yaptığınızdan
                  emin olun .</p>
                <hr />
                <b-field label="Banka" v-if="description">
                  <b-input v-model="description.bankName" disabled expanded />
                </b-field>
                <b-field label="Alıcı Adı" v-if="description">
                  <b-input v-model="description.bankAccountName" disabled expanded />
                </b-field>
                <b-field label="Alıcı Adresi" v-if="description">
                  <b-input v-model="description.iban" disabled expanded id="bankAccount" />
                  <p class="control">
                    <b-button type="is-success" icon-left="clipboard-check-multiple" @click="copy"></b-button>
                  </p>
                </b-field>
                <b-field label="Tutar(₺)" v-if="form.amount">
                  <b-input v-model="form.amount" disabled expanded />
                </b-field>
                <hr />
                <p class="mb-3 notification has-background-success-light p-3">
                  İşlem kontrol edildikten sonra bakiyenize yansıyacaktır. Lütfen sayfadan ayrılmayınız</p>
                <hr />
                <b-button type="is-danger" @click="cancel">İptal Et</b-button>
                <b-button type="is-success" class="ml-3" @click="pay">Ödemeyi Gerçekleştirdim</b-button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import {
  IntegrationService,
  TransactionService,
  UserService,
} from "@/services";

export default {
  name: "BankTransferIframe",
  components: { CardComponent },
  data() {
    return {
      loading: false,
      error: false,
      errMsg: "",
      tab: 0,
      form: {
        appKey: this.$route.query.appKey,
        appSecret: this.$route.query.appSecret,
        username: this.$route.query.username,
        name: this.$route.query.name,
        amount: this.$route.query.amount,
        userId: this.$route.query.userId,
        processId: this.$route.query.processId || null,
        jwt: this.$route.query.jwt
      },
      bankAccount: null,
      accountList: [],
      transactionId: null,
      response: {},
      interval: null,
      description: null
    };
  },
  computed: {
    isValid() {
      return (
        this.form.appKey &&
        this.form.appSecret &&
        this.form.userId &&
        this.form.username &&
        this.form.processId &&
        this.form.name &&
        this.form.amount >= 50
      );
    },
  },
  mounted() {
    const html = document.getElementsByTagName("html")[0];
    html.classList = [];
    this.init();
  },
  methods: {
    pay() {
      this.loading = true
      TransactionService.bankTransferPay({ transactionId: this.transactionId })
        .then(res => {
          this.$buefy.toast.open({ message: res.data.message, position: 'is-top', type: 'is-warning' })
          setTimeout(() => {
            window.location.href = res.data.redirectUrl
          }, 3000)
        })
        .finally(() => this.loading = false)
    },
    cancel() {
      this.loading = true
      TransactionService.cancel({ transactionId: this.transactionId })
        .then(res => {
          this.$buefy.toast.open({
            message: res.data.message,
            position: 'is-top-right',
            type: 'is-success',
            duration: 2000
          })
          setTimeout(() => {
            window.location.href = res.data.redirectUrl
          }, 2000)
        })
        .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-bottom', type: 'is-info' }))
        .finally(() => this.loading = false)
    },
    check() {
      TransactionService.check(this.transactionId)
        .then(({ data }) => {
          this.description = JSON.parse(data.requestParams.description)
          if (data.status === 'SUCCESSFUL') {
            // clearInterval(this.interval)
            if (data.requestParams.successRedirectUrl)
              window.location.href = data.requestParams.successRedirectUrl
            else
              this.$router.push({ name: 'coinSuccess' })
          }
          if (data.status === 'UNSUCCESSFUL') {
            // clearInterval(this.interval)
            if (data.requestParams.failRedirectUrl)
              window.location.href = data.requestParams.failRedirectUrl
            else
              this.$router.push({ name: 'coinError' })
          }
        })
        .catch(err => {
          this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' })
        })
    },
    copy() {
      const copyText = document.getElementById('bankAccount')
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(copyText.value)
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Kopyalandı!',
        position: 'is-bottom',
        type: 'is-success'
      })
    },
    async init() {
      UserService.providerCheck(this.form)
        .then(() => (this.tab = 1))
        .catch(() => (this.error = true));
      const { data } = await TransactionService.hashCreate(null, this.form.appSecret);
      IntegrationService.autoBankList(null, data, this.form.appKey).then(res => this.accountList = res.data)
    },
    async save() {
      const request = {
        username: this.form.username,
        name: this.form.name,
        amount: this.form.amount,
        userId: this.form.userId,
        bankId: this.form.bankId,
        processId: this.form.processId,
        jwt: this.form.jwt,
        description: JSON.stringify(this.accountList.find(item => item.id === this.form.bankId))
      };
      const { data } = await TransactionService.hashCreate(request, this.form.appSecret);
      IntegrationService.autoBankTransferDeposit(request, data, this.form.appKey)
        .then((res) => {
          this.transactionId = res.data.transactionId;
          this.response = res.data
          this.description = res.data.bank
          this.tab = 2
        })
        .catch((err) => {
          this.tab = 0;
          this.errMsg = err.message
        });
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>
